import '../scss/style.scss';
import { tns } from "tiny-slider/src/tiny-slider";
import Swiper, { Navigation, Pagination } from 'swiper';
import 'swiper/swiper-bundle.min.css';
import L from 'leaflet';
import { Fancybox } from "@fancyapps/ui/dist/fancybox/fancybox.esm.js";

var Sticky = require('sticky-js');

document.addEventListener('DOMContentLoaded', function () {

    const videoGallery = document.getElementById('video-gallery');

    const options = {
    loop: true,
    animationEffect: 'fade',
    buttons: [
        'zoom',
        'slideShow',
        'fullScreen',
        'thumbs',
        'close',
    ],
    };

    Fancybox.bind('[data-fancybox]', options);

    var swiper = new Swiper('.swiper-phrases', {
        modules: [Navigation, Pagination],
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        breakpoints: {
            0: {
                autoHeight: true,
            },
            1000 : {
                autoHeight: false,
            }
        }
    });

    if (document.querySelector('#swiper-phrases')) {
        var slidert1 = tns({
            container: '#swiper-phrases',
            mouseDrag: true,
            items: 3,
            edgePadding: 10,
            center: true,
            gutter: 25,
            slideBy: 'page',
            swipeAngle: false,
            speed: 400,
            autoplay: false,
            slideBy: 1,
            prevButton: '.tns-prev1',
            nextButton: '.tns-next1',
            responsive: {
                0: {
                    items: 1,
                    edgePadding: 30,
                },
                700: {
                    items: 1,
                    edgePadding: 100,
                },
                1000: {
                    items: 2,
                    edgePadding: 10,
                },
                1300: {
                    items: 3,
                    edgePadding: 10,
                }
            },
            onInit: function(event) {
                console.log('Elemento central:11', event.index);
                var items = document.querySelectorAll('.phrases-container2 .tns-item');
                    items.forEach(function(item) {
                    item.classList.remove('center-content');
                });
                items[event.index].classList.add('center-content');
            }
        });

        slidert1.events.on('indexChanged', function(event) {
            console.log('Elemento central:', event.index);
            var items = document.querySelectorAll('.phrases-container2 .tns-item');
                items.forEach(function(item) {
                item.classList.remove('center-content');
            });
            items[event.index].classList.add('center-content');
        });
    }

    if (document.querySelector('#square-mobile')) {
        var slidert3 = tns({
            container: '#square-mobile',
            mouseDrag: true,
            loop: false,
            items: 3,
            edgePadding: 10,
            gutter: 25,
            slideBy: 'page',
            swipeAngle: false,
            slideBy: 1,
            speed: 400,
            autoplay: false,
            prevButton: '.tns-prev',
            nextButton: '.tns-next',
            responsive: {
                0: {
                    items: 1,
                },
                700: {
                    items: 2,
                },
                1200: {
                    items: 3,
                }
            },
        });
    };

    if (document.querySelector('#swiper-phrases2')) {
        var slidert2 = tns({
            container: '#swiper-phrases2',
            mouseDrag: true,
            loop: false,
            items: 3,
            edgePadding: 10,
            gutter: 25,
            slideBy: 'page',
            swipeAngle: false,
            speed: 400,
            slideBy: 1,
            autoplay: false,
            prevButton: '.tns-prev2',
            nextButton: '.tns-next2',
            responsive: {
                0: {
                    items: 1,
                    edgePadding: 0,
                },
                680: {
                    items: 2,
                    edgePadding: 70,
                },
                700: {
                    items: 1,
                    edgePadding: 70,
                },
                900: {
                    items: 2,
                    edgePadding: 70,
                },
                1200: {
                    items: 3,
                    edgePadding: 10,
                }
            },
        });
    };
    
    if (document.querySelector('#swiper-phrases3')) {
        var slidert3d = tns({
            container: '#swiper-phrases3',
            mouseDrag: true,
            loop: false,
            items: 3,
            edgePadding: 10,
            gutter: 25,
            slideBy: 'page',
            swipeAngle: false,
            slideBy: 1,
            speed: 400,
            autoplay: false,
            prevButton: '.tns-prev3',
            nextButton: '.tns-next3',
            responsive: {
                0: {
                    items: 1,
                    edgePadding: 0,
                },
                700: {
                    items: 1,
                    edgePadding: 10,
                },
                1000: {
                    items: 2,
                    edgePadding: 10,
                },
                1200: {
                    items: 3,
                }
            },
        });
    };

    if (document.querySelector('#swiper-play')) {
        var play = tns({
            container: '#swiper-play',
            mouseDrag: true,
            items: 4,
            edgePadding: 10,
            center: true,
            gutter: 25,
            slideBy: 'page',
            swipeAngle: false,
            speed: 400,
            autoplay: false,
            slideBy: 1,
            prevButton: '.tns-prev5',
            nextButton: '.tns-next5',
            responsive: {
                0: {
                    items: 1,
                    edgePadding: 30,
                },
                700: {
                    items: 1,
                    edgePadding: 100,
                },
                1000: {
                    items: 2,
                    edgePadding: 10,
                },
                1200: {
                    items: 4,
                    edgePadding: 10,
                },
                1300: {
                    items: 4,
                    edgePadding: 50,
                },
            },
            onInit: function(event) {
                var items = document.querySelectorAll('.play-container2 .tns-item');
                    items.forEach(function(item) {
                    item.classList.remove('center-content');
                });
                items[event.index].classList.add('center-content');
            }
        });

        play.events.on('indexChanged', function(event) {
            var items = document.querySelectorAll('.play-container2 .tns-item');
                items.forEach(function(item) {
                item.classList.remove('center-content');
            });
            items[event.index].classList.add('center-content');
        });
    }

    if (document.querySelector('#tabs-slider')) {
        var slidert2 = tns({
            container: '#tabs-slider',
            mouseDrag: true,
            loop: false,
            items: 1,
            edgePadding: 0,
            gutter: 25,
            slideBy: 'page',
            swipeAngle: false,
            speed: 400,
            slideBy: 1,
            autoplay: false,
            prevButton: '.tns-prev5',
            nextButton: '.tns-next5',
        });
    }

    if (document.querySelector('#square-mobile2')) {
        var slidert3 = tns({
            container: '#square-mobile2',
            mouseDrag: true,
            loop: false,
            items: 3,
            edgePadding: 10,
            gutter: 25,
            slideBy: 'page',
            swipeAngle: false,
            slideBy: 1,
            speed: 400,
            autoplay: false,
            prevButton: '.square-prev4',
            nextButton: '.square-next4',
            responsive: {
                0: {
                    items: 1,
                },
                700: {
                    items: 2,
                },
                1200: {
                    items: 3,
                }
            },
        });
    }
                
    const formFields = document.querySelectorAll(".form-home input");

    function toggleFilledClass(field) {
        const label = field.closest("label");
        if (field.value) {
            label.classList.add("filled");
        } else {
            label.classList.remove("filled");
        }
    }

    formFields.forEach((field) => {
        field.addEventListener("input", () => {
            toggleFilledClass(field);
        });

        field.addEventListener("blur", () => {
            toggleFilledClass(field);
        });

        field.addEventListener("focus", () => {
            const label = field.closest("label");
            label.classList.add("filled");
        });
    });

    if (document.querySelector('.tab-link')) {
        const tabLinks = document.querySelectorAll(".tab-link");
        const tabItems = document.querySelectorAll(".tab-item");

        function openTab(tab) {
            tabItems.forEach(item => item.classList.remove("active"));
            tabLinks.forEach(link => link.classList.remove("active"));
            document.getElementById(tab.dataset.tab).classList.add("active");
            tab.classList.add("active");
        }

        document.querySelector(".tab-link.active").click();

        tabLinks.forEach(link => link.addEventListener("click", () => openTab(link)));
    }

    if (document.querySelector('.search')) {
        const search = document.getElementById('search');
        const closebtn = document.getElementById('close-btn');
        const elementModal = document.getElementById('modal-search');
        const elementModal2 = document.getElementById('mobile-navigation');

        function fadeIn(element) {
            element.style.display = 'block';
            let opacity = 0;
            const interval = setInterval(function() {
                if (opacity >= 1) {
                    clearInterval(interval);
                }
                element.style.opacity = opacity;
                element.style.filter = 'alpha(opacity=' + opacity * 100 + ")";
                opacity += 0.1;
            }, 50);
        }

        function fadeOut_mobile(element) {
            let opacity = 1;
            const interval = setInterval(function() {
                opacity += 0.1;
                elementModal2.style.opacity = opacity;
                elementModal2.style.filter = `alpha(opacity=${opacity * 100})`;
                if (opacity <= 0) {
                    clearInterval(interval);
                    element.style.display = 'none';

                    elementModal2.style.display = 'block';
                    elementModal2.style.opacity = opacity;
                }
            }, 50);
        }

        search.addEventListener('click', function(event) {
            event.preventDefault();
            fadeIn(elementModal);
            fadeOut_mobile(elementModal2);
        });

        function fadeOut(element) {
            let opacity = 1;
            const interval = setInterval(function() {
                opacity -= 0.1;
                element.style.opacity = opacity;
                element.style.filter = `alpha(opacity=${opacity * 100})`;
                if (opacity <= 0) {
                    clearInterval(interval);
                    element.style.display = 'none';
                }
            }, 50);
        }
        
        closebtn.addEventListener('click', function(event) {
            event.preventDefault();
            fadeOut(elementModal);
        });
    }

    if (document.querySelector('#open-nav')) {
        const search2 = document.getElementById('open-nav');
        const elementModal2 = document.getElementById('mobile-navigation');

        function fadeIn2(element) {
            element.style.display = 'block';
            let opacity = 0;
            const interval = setInterval(function() {
                if (opacity >= 1) {
                    clearInterval(interval);
                }
                element.style.opacity = opacity;
                element.style.filter = 'alpha(opacity=' + opacity * 100 + ")";
                opacity += 0.1;
            }, 15);

            document.body.classList.add('scroll-none');
            document.documentElement.classList.add('scroll-none'); 
            
            document.querySelector('#search').style.display = 'block'; 
            document.querySelector('#open-nav').style.display = 'none';
            document.querySelector('#close-nav').style.display = 'block';  
        }

        search2.addEventListener('click', function(event) {
            event.preventDefault();
            fadeIn2(elementModal2);
        });
    }

    if (document.querySelector('#close-nav')) {
        const closebtn = document.getElementById('close-nav');
        const elementModal2 = document.getElementById('mobile-navigation');
        const closemodal = document.getElementById('modal-search');

        function fadeOut2(element) {
            let opacity = 1;
            const interval = setInterval(function() {
                opacity -= 0.1;
                element.style.opacity = opacity;
                element.style.filter = `alpha(opacity=${opacity * 100})`;
                if (opacity <= 0) {
                    clearInterval(interval);
                    element.style.display = 'none';
                }
            }, 15);

            document.querySelector('#search').style.display = 'none'; 
            document.body.classList.remove('scroll-none');
            document.documentElement.classList.remove('scroll-none'); 

            document.querySelector('#close-nav').style.display = 'none';
            document.querySelector('#open-nav').style.display = 'block';  
        }

        function fadeOut_mobile(element) {
            let opacity = 1;
            const interval = setInterval(function() {
                opacity -= 0.1;
                closemodal.style.opacity = opacity;
                closemodal.style.filter = `alpha(opacity=${opacity * 100})`;
                if (opacity <= 0) {
                    clearInterval(interval);
                    element.style.display = 'none';
                }
            }, 15);
        }
        
        closebtn.addEventListener('click', function(event) {
            event.preventDefault();
            fadeOut2(elementModal2);
            fadeOut_mobile(closemodal);
        });
    }

    if (document.querySelector("#map")) {
        const obj = document.querySelector("#map");
        const map = L.map("map", {
          scrollWheelZoom: false,
        });

        const latitude = obj.dataset.latitude
          ? parseFloat(obj.dataset.latitude)
          : 6.2352718;
        const longitude = obj.dataset.longitude
          ? parseFloat(obj.dataset.longitude)
          : -75.5801991;
        const zoom = obj.dataset.zoom ? parseInt(obj.dataset.zoom, 10) : 18;
      
        map.setView([latitude, longitude], zoom);
      
        L.tileLayer("https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png", {
          attribution:
            'Map data &copy; <a href="https://www.openstreetmap.org/">OpenStreetMap</a> contributors',
          maxZoom: 18,
        }).addTo(map);
      
        L.marker([latitude, longitude]).addTo(map);
      }

    if (document.querySelector('.accordion-header')) {
        const accordionHeaders = document.querySelectorAll('.accordion-header');

        accordionHeaders.forEach(header => {
            header.addEventListener('click', () => {
                const accordionContent = header.nextElementSibling;

                accordionHeaders.forEach(otherHeader => {
                    if (otherHeader !== header) {
                        const otherAccordionContent = otherHeader.nextElementSibling;
                        otherHeader.classList.remove('active');
                        otherAccordionContent.style.display = 'none';
                        otherHeader.querySelector('.accordion-icon').textContent = '+';
                    }
                });

                header.classList.toggle('active');
                if (accordionContent.style.display === 'block') {
                    accordionContent.style.display = 'none';
                    header.querySelector('.accordion-icon').textContent = '+';
                } else {
                    accordionContent.style.display = 'block';
                    header.querySelector('.accordion-icon').textContent = '-';
                }
            });
        });
    }
    if (window.innerWidth > 1100) {
        var sticky = new Sticky('.sticky-social');
    }
});

document.addEventListener('DOMContentLoaded', () => {
    const form = document.querySelector('.contact-form-component');
    const formResponse = document.querySelector('.ajax-message');
    
    if (formResponse) {
        formResponse.textContent = '';
        //hide the message
        formResponse.style.display = 'none';
    }
    if (!form) return;
  
    
    let ajaxInProgress = false;
  
    const updateFormResponse = (type, message) => {
      if (formResponse) {
        formResponse.textContent = message;
        formResponse.classList.remove('alert-danger', 'alert-success');
        formResponse.classList.add(type);
      }
    };
  
    form.addEventListener('submit', async (event) => {
      event.preventDefault();

      if (formResponse) {
        formResponse.textContent = '';
        formResponse.style.display = 'none';
    }
  
      if (ajaxInProgress) {
        return;
      }
  
      ajaxInProgress = true;
  
      const formData = new FormData(form);
  
      try {
        const response = await fetch(form.getAttribute('action'), {
          method: form.method,
          body: formData,
        });
  
        if (!response.ok) {
          throw new Error(response);
        }
  
        const jsonResponse = await response.json();
  
        if (!jsonResponse.success) {
          throw new Error(jsonResponse);
        }
  
        updateFormResponse('alert-success', jsonResponse.data);
        form.reset();
      } catch (errorResponse) {
        updateFormResponse('alert-danger', window.i18n.anErrorHasOcurred);
      } finally {
        formResponse.style.display = 'block';
        ajaxInProgress = false;
      }
    });
});
  
document.addEventListener('DOMContentLoaded', () => {
    const categoryFilter = document.getElementById('category-filter');
    const orderFilter = document.getElementById('order-filter');

    //validate if exists categoryFilter
    if (categoryFilter) {
        categoryFilter.addEventListener('change', function () {
            updateURLParams('category', this.value);
        });
    }

    if (orderFilter) {
        orderFilter.addEventListener('change', function () {
            updateURLParams('order', this.value);
        });
    }

    function updateURLParams(param, value) {
        const url = new URL(window.location.href);

        if (value === '') {
            url.searchParams.delete(param);
        } else {
            url.searchParams.set(param, value);
        }

        window.location.href = url.toString();
    }
});

document.addEventListener("DOMContentLoaded", function() {
    const shareLinks = document.querySelectorAll(".share-popup");

    shareLinks.forEach(link => {
        link.addEventListener("click", (e) => {
        e.preventDefault();

        const url = link.getAttribute("data-url");
        const popupWidth = 600;
        const popupHeight = 400;
        const left = (window.innerWidth / 2) - (popupWidth / 2);
        const top = (window.innerHeight / 2) - (popupHeight / 2);
        const popup = window.open(url, "Compartir", `width=${popupWidth},height=${popupHeight},left=${left},top=${top},toolbar=0,scrollbars=0,status=0,resizable=1,location=0,menuBar=0`);
        return false;
        });
    });
});

window.addEventListener('load', () => {
    const LogoHeaderLink = document.querySelector('#link-logo-header');
    const logoHeader = document.querySelector('#logo-header');

    const scrollLogoSrc = LogoHeaderLink.dataset.scrollLogo;
    const defaultLogoSrc = LogoHeaderLink.dataset.defaultLogo;

    console.log(scrollLogoSrc);

    window.addEventListener('scroll', () => {
        const scrollPosition = window.scrollY;

        if (scrollPosition > 0) {
            document.documentElement.classList.add("scrolled");
            logoHeader.setAttribute('src', scrollLogoSrc);
        } else {
            document.documentElement.classList.remove("scrolled");
            logoHeader.setAttribute('src', defaultLogoSrc);
        }
    });
});

document.addEventListener("DOMContentLoaded", function () {
    const searchForm = document.getElementById("search-form");
    const searchInput = searchForm.querySelector("input[type='text']");
    const searchResults = document.getElementById("search-results");

    if (!searchForm || !searchInput || !searchResults) {
        return;
    }

    const ajaxUrl = searchForm.dataset.ajaxUrl;
    let debounceTimer;

    searchInput.addEventListener("input", function () {
        clearTimeout(debounceTimer);

        const query = searchInput.value;

        if (query.length > 2) {
            debounceTimer = setTimeout(() => {
                fetch(ajaxUrl + "?action=search_ajax&query=" + encodeURIComponent(query))
                    .then(response => {
                        if (response.ok) {
                            return response.text();
                        } else {
                            throw new Error('Error en la petición AJAX');
                        }
                    })
                    .then(text => {
                        searchResults.innerHTML = text;
                    })
                    .catch(error => {
                        console.error('Error:', error);
                    });
            }, 300); // 300 ms de espera
        } else {
            searchResults.innerHTML = "";
        }
    });
});
